exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".CancelBookingForm-textAlignCenter-2U6kh{\n    text-align: center;\n}\n.CancelBookingForm-marginTop-2hAc5{\n    margin-top: 22px;\n}", "", {"version":3,"sources":["/code/src/components/ManualBooking/CancelBookingModal/CancelBookingForm/CancelBookingForm.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;CACtB;AACD;IACI,iBAAiB;CACpB","file":"CancelBookingForm.css","sourcesContent":[".textAlignCenter{\n    text-align: center;\n}\n.marginTop{\n    margin-top: 22px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"textAlignCenter": "CancelBookingForm-textAlignCenter-2U6kh",
	"marginTop": "CancelBookingForm-marginTop-2hAc5"
};