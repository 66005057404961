import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  Row,
  FormGroup,
  Col,
  FormControl,
  Container,
  Popover,
  OverlayTrigger
} from 'react-bootstrap';
import cx from 'classnames';
import { injectIntl } from 'react-intl';

import Loader from '../../Common/Loader/Loader';

import messages from '../../../locale/messages'
import submit from './submit'
import validate from './validate'

import Faq from '../../../../public/Icons/question_info.svg';

import s from './ConfigSettingsForm.css';
import bt from '../../../components/commonStyle.css';
export class ConfigSettingsForm extends Component {
  static defaultProps = {
    loading: false
  };


  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength, toolTip }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={s.formGroup}>
          <div>
            <label className={cx(bt.labelText, s.normalFontWeight)} >{label}  {toolTip && this.renderTooltip(toolTip)}</label>
          </div>
          <div>
            <FormControl {...input} placeholder={label} type={type} className={bt.formControlInput} maxlength={maxlength} />
            {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  }

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className, toolTip }) => {
    const { formatMessage } = this.props.intl
    return (
      <div>
        <FormGroup className={s.formGroup}>
          <div>
            <label className={bt.labelText} >{label} {toolTip && this.renderTooltip(toolTip)}</label>
          </div>
          <div>
            <FormControl
              {...input}
              className={bt.formControlInput}
              placeholder={label}
              as="textarea"
              rows="3"
            >
              {children}
            </FormControl>
            {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  }

  renderTooltip = (label) => {
    return (
      <>
        <OverlayTrigger overlay={<Popover>{label}</Popover>}>
          <img src={Faq} className={cx(s.faqImage, 'faqDarkMode')} />
        </OverlayTrigger>
      </>
    )
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, logo, loading, submitting, appForceUpdate, tripTone, deleteTripTone, tripToneFile } = this.props;
    const waitingTime = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
      <div>
        <div className={s.paddingTop}>
          <Container fluid>
            <form onSubmit={handleSubmit(submit)}>

              <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.stripeSettings)}</h5>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="stripePublishableKey" type="text" component={this.renderFormControl} label={formatMessage(messages.stripePublishableKey)} toolTip={formatMessage(messages.stripePublishKeyTooltip)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="stripeSecretKey" type="text" component={this.renderFormControl} label={formatMessage(messages.stripeSecretKey)} toolTip={formatMessage(messages.stripeSecrectKeyTooltip)} />
                </Col>
              </Row>

              <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.twilio)}</h5>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="twillioAccountSid" type="text" component={this.renderFormControl} label={formatMessage(messages.twillioAccountSid)} toolTip={formatMessage(messages.twilioAccountTooltip)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="twillioAuthToken" type="text" component={this.renderFormControl} label={formatMessage(messages.twillioAuthToken)} toolTip={formatMessage(messages.twilioAuthTooltip)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="twillioPhone" type="text" component={this.renderFormControl} label={formatMessage(messages.twillioPhone)} toolTip={formatMessage(messages.twilioPhonenumberTooltip)} />
                </Col>
              </Row>

              <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.otherSettings)}</h5>
              <Row>
                <Col xs={12} sm={12} md={12} lg={10} xl={6} className={bt.space2}>
                  <Field name="maxDistance" type="text" component={this.renderFormControl} label={formatMessage(messages.allowableDistaceTitle) + ' (KM)'} toolTip={formatMessage(messages.maxDistanceTooltip)} />
                </Col>
              </Row>
              <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.fcmPushNotificationSettings)}</h5>
              <Row>
                <Col xs={12} sm={12} md={12} lg={10} xl={6} className={bt.space2}>
                  <Field name="fcmPushNotificationKey" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.fcmPushNotificationKey)} toolTip={formatMessage(messages.fcmKeyTooltip)} />
                </Col>
              </Row>
              <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.paystackSettings)}</h5>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12} xs={12} className={bt.space2}>
                  <FormGroup className={s.formGroup}>
                    <label className={cx(bt.labelText, s.normalFontWeight)} >{formatMessage(messages.paystackCountry)}<>{this.renderTooltip(formatMessage(messages.payStackCountryTooltip))}</></label><br />
                    <Field name="paystackCountry" className={cx(bt.formControlSelect, bt.formControlInput)} component="select">
                      <option value="ZA">{formatMessage(messages.southafrica)}</option>
                      <option value="NG">{formatMessage(messages.nigeria)}</option>
                      <option value="GH">{formatMessage(messages.ghana)}</option>
                    </Field>
                  </FormGroup>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} xs={12} className={bt.space2}>
                  <FormGroup className={s.formGroup}>
                    <label className={cx(bt.labelText, s.normalFontWeight)} >{formatMessage(messages.paystackCurrency)}<>{this.renderTooltip(formatMessage(messages.paystackCurrencyTooltip))}</></label><br />
                    <Field name="paystackCurrency" className={cx(bt.formControlSelect, bt.formControlInput)} component="select">
                      <option value="ZAR">{formatMessage(messages.zar)}</option>
                      <option value="NGN">{formatMessage(messages.ngn)}</option>
                      <option value="GHS">{formatMessage(messages.ghs)}</option>
                    </Field>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="paystackSecretKey" type="text" component={this.renderFormControl} label={formatMessage(messages.paystackSecretKey)} toolTip={formatMessage(messages.paystackSecretKeyTooltip)} />
                </Col>
              </Row>
              <Col lg={12} md={12} sm={12} xs={12} className={cx(bt.textAlignRight, bt.spaceTop3, 'textAlignLeftRTL', 'loadingBtnRTL')}>
                <FormGroup className={s.formGroup}>
                  <div>
                    <Loader
                      type={"button"}
                      label={formatMessage(messages.submitButton)}
                      show={loading}
                      buttonType={'submit'}
                      className={cx(bt.btnPrimary)}
                      disabled={submitting || loading}
                      isSuffix={true}
                    />
                  </div>
                </FormGroup>
              </Col>

            </form>
          </Container>
        </div>
      </div>
    )
  }
}

ConfigSettingsForm = reduxForm({
  form: 'ConfigSettingsForm',
  onSubmit: submit,
  validate,
})(ConfigSettingsForm);

const selector = formValueSelector('ConfigSettingsForm')

const mapState = (state) => ({
  loading: state.loader.ConfigSettings,
})
const mapDispatch = {
}
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(ConfigSettingsForm)));