import messages from '../../../locale/messages'

const validate = values => {

  const errors = {}


  if (!values.stripePublishableKey) {
    errors.stripePublishableKey = messages.required;
  } else if (values.stripePublishableKey && values.stripePublishableKey.toString().trim() == "") {
    errors.stripePublishableKey = messages.required;
  }

  if (!values.stripeSecretKey) {
    errors.stripeSecretKey = messages.required;
  } else if (values.stripeSecretKey && values.stripeSecretKey.toString().trim() == "") {
    errors.stripeSecretKey = messages.required;
  }

  if (!values.twillioAccountSid) {
    errors.twillioAccountSid = messages.required;
  } else if (values.twillioAccountSid && values.twillioAccountSid.toString().trim() == "") {
    errors.twillioAccountSid = messages.required;
  }

  if (!values.twillioPhone) {
    errors.twillioPhone = messages.required;
  } else if (values.twillioPhone && values.twillioPhone.toString().trim() == "") {
    errors.twillioPhone = messages.required;
  }

  if (!values.twillioAuthToken) {
    errors.twillioAuthToken = messages.required;
  } else if (values.twillioAuthToken && values.twillioAuthToken.toString().trim() == "") {
    errors.twillioAuthToken = messages.required;
  }

  if (!values.maxDistance) {
    errors.maxDistance = messages.required;
  } else if (values.maxDistance && values.maxDistance.toString().trim() == "") {
    errors.maxDistance = messages.required;
  } else if (isNaN(values.maxDistance)) {
    errors.maxDistance = messages.numericValue;
  }

  if (!values.fcmPushNotificationKey) {
    errors.fcmPushNotificationKey = messages.required;
  } else if (values.fcmPushNotificationKey && values.fcmPushNotificationKey.toString().trim() == "") {
    errors.fcmPushNotificationKey = messages.required;
  }

  if (!values.paystackSecretKey) {
    errors.paystackSecretKey = messages.required;
  } else if (values.paystackSecretKey && values.paystackSecretKey.toString().trim() == "") {
    errors.paystackSecretKey = messages.required;
  }

  return errors
}

export default validate;